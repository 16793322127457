import PropTypes from 'prop-types'
import React from 'react'

const TheOnion = ({ className, cropped = true }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox={cropped ? '0 0 186 163' : '0 0 200 260'}
      preserveAspectRatio="xMidYMid"
      className={className}
    >
      <defs>
        <path id="a" d="M.004.199h104.875v89.625H.004z" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g transform="matrix(-1 0 0 1 105 9)">
          <mask id="b" fill="#fff">
            <use xlinkHref="#a" />
          </mask>
          <path
            d="M1.789 89.777c-3.176-1.395-3.785-32.917 12.677-57.849C26.724 13.364 49.367-2.942 74.662.716c15.762 2.28 30.622 12.034 30.21 15.397-.362 2.938-12.256-.103-28.625 2.246 0 0-20.369 2.922-37.529 14.574C9.146 53.012 5.382 91.356 1.788 89.777"
            fill="#E3E935"
            mask="url(#b)"
          />
        </g>
        <path
          d="M105.41 105.994c-3.48-.497-12.66-30.778-3.38-59.27C108.943 25.506 126.61 3.686 152.3.438c16.01-2.023 33.176 3.414 33.688 6.772.448 2.935-11.992 3.186-27.335 9.843 0 0-19.09 8.284-32.67 24.137-23.404 27.321-16.635 65.365-20.573 64.803"
          fill="#E3E935"
        />
        <path
          d="M112.625 79.552c22.028 15.62 46.506 41.455 49.232 86.58.798 13.224-1.133 39.69-21.613 58.286-20.147 18.293-54.458 24.515-78.44 7.977-27.48-18.95-25.174-56.59-24.407-69.1 2.29-37.384 27.573-71.554 52.524-83.614 9.502-4.592 19.56-2.36 22.704-.129"
          fill="#EAB8E4"
        />
        <path
          d="M92 128.735c.061 4.416-8.618 8.114-19.387 8.26-10.77.147-19.55-3.314-19.613-7.73-.062-4.416 8.618-8.114 19.387-8.26 10.77-.147 19.55 3.314 19.613 7.73"
          fill="#FFF"
        />
        <path
          d="M90 128.382c.034 2.485-3.744 4.551-8.438 4.616-4.694.065-8.527-1.896-8.562-4.38-.034-2.485 3.743-4.551 8.438-4.616 4.694-.065 8.527 1.896 8.562 4.38"
          fill="#000"
        />
        <path
          d="M153 128.735c.062 4.416-8.618 8.114-19.388 8.26-10.769.147-19.55-3.314-19.612-7.73-.062-4.416 8.618-8.114 19.388-8.26 10.769-.147 19.55 3.315 19.612 7.73"
          fill="#FFF"
        />
        <path
          d="M151 128.382c.034 2.485-3.744 4.551-8.438 4.616-4.694.065-8.527-1.896-8.562-4.38-.034-2.485 3.743-4.551 8.438-4.616 4.694-.065 8.527 1.896 8.562 4.38M112.873 147.398c-.284.545-.898.773-1.394.913-.784.22-1.605.234-2.405.19a13.522 13.522 0 01-5.361-1.443c-.71-.361-1.019 1.07-.317 1.428a14.129 14.129 0 006.07 1.51c1.488.03 3.537-.122 4.436-1.85.418-.803-.614-1.546-1.03-.748"
          fill="#000"
        />
      </g>
    </svg>
  )
}

TheOnion.propTypes = {
  className: PropTypes.string,
  cropped: PropTypes.bool
}

export { TheOnion }
