/** @jsx jsx */
import { graphql, Link } from 'gatsby'
import { Box, jsx } from 'theme-ui'
import HeroSection from '~/components/Content/Section/Hero'
import Layout from '~/components/Layout'
import Metadata from '~/components/Metadata'
import { TheOnion } from '~/components/Characters/TheOnion'

const constants = {
  backgroundColor: '#F5EFE9',
  navTextColor: '#851B54',
  logoColor: '#851B54',
}

const mediaType = media => {
  return media.__typename
}

const titleColors = [
  'rgb(239, 54, 66)',
  'rgb(0, 15, 159)',
  'rgb(133, 27, 84)',
  'rgb(0, 93, 95)',
]
let currentColorIdx = 0
const getTitleColor = () => {
  if (currentColorIdx === 4) {
    currentColorIdx = 0
  }
  return titleColors[currentColorIdx++]
}

const SweatEducation = ({ data }) => {
  const { page } = data

  return (
    <Layout
      backgroundColor={constants.backgroundColor}
      navTextColor={constants.navTextColor}
      logoColor={constants.logoColor}
    >
      <Metadata title={page.name} />
      <Box
        sx={{
          margin: '0 auto',
          maxWidth: 'calc(1200px + 3rem)',
          padding: '0 1.5rem',
          position: 'relative',
        }}>

          {page.sections.map(section => {
            return (section.media.map((media, index) => {
              return (
                <div>
                  {mediaType(media) === 'ContentfulHero' && (
                    <HeroSection hero={media} textColor={'rgb(133, 27, 84)'} />
                  )}
                  {mediaType(media) === 'ContentfulArticle' && (
                    <Box
                    key={media.id}
                    sx={{
                      margin: '0 auto',
                      padding: ['1rem 0', '2rem 0'],
                      textAlign: ['left', 'center']
                    }}>
                      <Link
                       to={`/sweat-education-blog/${media.slug}`}
                        sx={{
                          color: getTitleColor(),
                          fontSize: ['34px', '55px'],
                          lineHeight: ['40px', '60px'],
                          textDecoration: 'underline',
                        }}
                      >
                        {media.title}
                      </Link>
                    </Box>
                  )}
                  </div>
                )
            }))
          })}

          <Box sx={{
            position: 'relative',
            left: 0,
            bottom: '-7px',
            width: '10rem',
          }}>
            <TheOnion />
          </Box>

      </Box>
    </Layout>
  )
}

export default SweatEducation

export const query = graphql`
  query SweatEducationPage($locale: String) {
    page: contentfulPage(
      slug: { eq: "sweat-education-blog" }
      node_locale: { eq: $locale }
    ) {
      ...ContentPageFragment
    }
  }
`
